import { isUndefined } from 'lodash'
import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()
let amplitude: any;

export const initAmplitude = () => {
  amplitude = require('amplitude-js')
  amplitude.getInstance().init(publicRuntimeConfig.amplitudeApiKey);
}

export const logEvent = (event: string): any => {
  if (isUndefined(amplitude)) {
    return setTimeout(() => logEvent(event), 10000)
  }
  return amplitude.getInstance().logEvent(event)
}
