import { getConfig, setConfigEnv } from '../config/envVars'
import { initAmplitude } from '../utilities/analytics'
import App from 'next/app'
import Head from 'next/head'
import '../public/static/styles/empty.css'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
// import { withReduxStore } from '../utilities/store'
// import { withSecureHeaders } from 'next-secure-headers'
// import { createSecureHeaders } from 'next-secure-headers'
// import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import browsee from '@browsee/web-sdk'
// import initHelloNext from '../utilities/analytics/hellonext'
// import { setConfig } from 'next/config'
import PlausibleProvider from 'next-plausible'
import { SWRDevTools } from 'swr-devtools'
// import nonce from 'utilities/nonce'

import '../public/static/styles/index.css'
import '../public/static/styles/fonts.css'
import '../public/static/styles/reactPhoneNumber.css'
import '../public/static/styles/mui.css'
import '../public/static/styles/flatpickr-style.css'

const materialTheme = createTheme({
  typography: {
    fontFamily: 'Montserrat, Tahoma, sans-serif',
    // fontSize: 20,
  },
  // overrides: {
  //   MuiPaper: {
  //     root: {
  //       minWidth: '300px',
  //     }
  //   },
  // }
})

// process.env.ENV was intended as a way to pass the environment (e.g., 'stage')
// on the CLI, because nextjs hardcodes as production whenever running a built
// version. This approach doesn't currently work though. :(
const env = process?.env?.ENV || process?.env?.NODE_ENV
setConfigEnv(env)
const config = getConfig()

class MyApp extends App {
  componentDidMount () {
    if (!_.isUndefined(window)) {
      initAmplitude()
    }
    // initHelloNext()
    window.cardhero = {}
    Sentry.init({
      dsn: config.sentryDsn,
      environment: env,
    })

    browsee.init({ apiKey: '85f5c6504cd9cc89e08517475745f4ca57ad26676bc6e66f' })

    window.cardhero.settings = {
      API_BASE: config.apiBase,
      STRIPE_PUBLIC_KEY: config.stripePublicKey,
    }
  }

  render () {
    const { Component, pageProps } = this.props
    return <>
      <SWRDevTools>
        <PlausibleProvider domain='thecardhero.com'>
          <Head>
            <title>CardHero</title>
            <meta name='viewport' content='width=device-width, initial-scale=1' />
            {/* Image CDN */}
            <link rel="preconnect" href="https://d3br9f94aes2qf.cloudfront.net" />
            <link rel="dns-prefetch" href="https://d3br9f94aes2qf.cloudfront.net" />
            <link
              rel='preload'
              media='(max-width: 759px)'
              as='image'
              imagesrcset={`
                https://d3br9f94aes2qf.cloudfront.net/cardhero_background_360.webp 1x,
                https://d3br9f94aes2qf.cloudfront.net/cardhero_background_360@2x.webp 2x
              `}
            />
            <link
              rel='preload'
              media='(min-width: 760px) and (max-width: 999px)'
              as='image'
              imagesrcset={`
                https://d3br9f94aes2qf.cloudfront.net/cardhero_background_1200.webp 1x,
                https://d3br9f94aes2qf.cloudfront.net/cardhero_background_1200@2x.webp 2x
              `}
            />
            <link
              rel='preload'
              media='(min-width: 1000px)'
              as='image'
              imagesrcset={`
                https://d3br9f94aes2qf.cloudfront.net/cardhero_background_1200@2x.webp 2x
              `}
            />
            {/* <link rel='preload' as='image' imagesrcset="cat.png 1x, cat-2x.png 2x"> */}
            {/* Font preload */}
            <link rel='preload' as='font' href='/static/fonts/montserrat-regular.woff2' crossOrigin='anonymous' />
            <link rel='preload' as='font' href='/static/fonts/montserrat-semibold.woff2' crossOrigin='anonymous' />
            <link rel='preload' as='font' href='/static/fonts/josefinsans-bold.woff2' crossOrigin='anonymous' />
          </Head>
          {/* <Provider store={ reduxStore }> */}
          <ThemeProvider theme={materialTheme}>
            <Component {...pageProps} />
          </ThemeProvider>
          {/* </Provider> */}
        </PlausibleProvider>
      </SWRDevTools>
    </>
  }
}

// export default withSecureHeaders(securityHeaders)(MyApp)
export default MyApp
